import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["element-loading-text"]
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-1" }
const _hoisted_4 = { class: "fw-bolder text-muted" }
const _hoisted_5 = { class: "fw-bolder text-hover-primary fs-6" }
const _hoisted_6 = { class: "d-flex flex-column w-100 me-2" }
const _hoisted_7 = { class: "d-flex flex-stack mb-2" }
const _hoisted_8 = { class: "me-2 fs-7 fw-bold" }
const _hoisted_9 = { class: "text-start" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "btn btn-primary btn-sm" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_PhonebookCreateModal = _resolveComponent("PhonebookCreateModal")!
  const _component_PhonebookNumbersModal = _resolveComponent("PhonebookNumbersModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["card", _ctx.widgetClasses])
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "card-body card-scroll py-3",
        "element-loading-text": _ctx.translate('loading')
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_4, [
                _createElementVNode("th", null, _toDisplayString(_ctx.translate("The title of the notebook")), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.translate("Number of numbers")), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.translate("")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(Number(item.numbers_count).toLocaleString()), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    _createElementVNode("button", {
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#kt_modal_phonebook_numbers",
                      class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-info me-1 mb-1",
                      onClick: ($event: any) => (_ctx.selected = item),
                      "data-testid": "open-phonebook-details"
                    }, _toDisplayString(_ctx.translate("Number management")), 9, _hoisted_10),
                    _createElementVNode("a", {
                      href: _ctx.download_excel(item.id),
                      download: ""
                    }, [
                      _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.translate("Download Excel")), 1)
                    ], 8, _hoisted_11),
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-danger me-1 mb-1",
                      onClick: ($event: any) => (_ctx.remove(item.id))
                    }, _toDisplayString(_ctx.translate("delete")), 9, _hoisted_13)
                  ])
                ]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.list && _ctx.list.length === 0)
          ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1)), [
        [_directive_loading, _ctx.loading]
      ]),
      (_ctx.pagination && _ctx.pagination.total_pages > 1)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            pagerCount: 4,
            background: "",
            layout: "prev, pager, next",
            class: "mt-4 mb-4 text-center direction-rtl",
            total: _ctx.pagination.total,
            currentPage: _ctx.pagination.current_page,
            "page-size": _ctx.pagination.per_page,
            onSizeChange: _ctx.handleSizeChange,
            onCurrentChange: _ctx.handleCurrentChange
          }, null, 8, ["total", "currentPage", "page-size", "onSizeChange", "onCurrentChange"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_PhonebookCreateModal, { onRefreshList: _ctx.getItems }, null, 8, ["onRefreshList"]),
    _createVNode(_component_PhonebookNumbersModal, {
      onRefreshList: _ctx.getItems,
      phonebook: _ctx.selected
    }, null, 8, ["onRefreshList", "phonebook"])
  ]))
}