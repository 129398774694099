
import { computed, defineComponent, ref, watch } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import JwtService from "@/core/services/JwtService";
import UploadFile from "@/components/uploadFiles/UploadFile.vue";

export default defineComponent({
  components: {
    UploadFile,
  },
  props: {
    phonebook: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const loading = ref(false);
    const page = ref(1);
    const phonebook = ref();
    const numbers = ref([]);
    const pagination = ref();
    const recipients = ref();
    const recipientsNumbers = computed(() => {
      if (!recipients.value) return;
      return recipients?.value.split("\n").filter((item) => item !== "");
    });

    const inputMode = ref("manual");
    const inputModeWays = ref(["manual", "excel"]);
    const importFile = ref();

    const getNumbers = () => {
      loading.value = true;
      ApiService.query(`phonebook/${phonebook.value.id}/numbers`, {
        params: {
          page: page.value,
        },
      })
        .then(({ data }) => {
          numbers.value = data.data.phonebook_numbers.data;
          pagination.value = data.data.phonebook_numbers.pagination;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

      const handleCurrentChange = (val: number) => {
      page.value = val;
      getNumbers();
    };

    const download_excel = (id) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `phonebook/${id}/download?api_token=${JwtService.getToken()}`
      );
    };

    watch(
      () => props.phonebook,
      (item) => {
        item = JSON.parse(JSON.stringify(item));
        phonebook.value = item;
        numbers.value = [];
        getNumbers();
      }
    );

    const remove = (item) => {
      ApiService.post(`phonebook/numbers/remove`, { id: item.id })
        .then(() => {
          getNumbers();
          emit("refreshList");
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };

    const uploadNumbers = () => {
      if (!confirm(translate("Are you sure of registering the numbers?")))
        return;

      ApiService.post(`phonebook/${phonebook.value.id}/number/import`, {
        import_file: importFile.value,
      })
        .then(() => {
          getNumbers();
          emit("refreshList");
          ElNotification({
            title: translate("success"),
            message: translate(
              "The numbers have been successfully added to the phonebook"
            ),
            type: "success",
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const submitButton = ref<HTMLButtonElement | null>(null);
    const AddNumbersManual = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post(`phonebook/${phonebook.value.id}/number/add`, {
        numbers: recipientsNumbers.value,
      })
        .then(() => {
          getNumbers();
          emit("refreshList");
          ElNotification({
            title: translate("success"),
            message: translate(
              "The numbers have been successfully added to the phonebook"
            ),
            type: "success",
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    const exelUploadedCallBack = (file) => {
      importFile.value = file.path;
      uploadNumbers();
    };

    return {
      translate,
      exelUploadedCallBack,
      getNumbers,
      handleCurrentChange,
      download_excel,
      remove,
      submitButton,
  toEnglishDigits,
      loading,
      pagination,
      page,
      numbers,
      importFile,
      inputMode,
      inputModeWays,
      recipients,
      recipientsNumbers,
      AddNumbersManual,
    };
  },
});
