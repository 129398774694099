
import { defineComponent, ref, onMounted } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import PhonebookCreateModal from "@/views/pages/other/phoneBook/component/PhonebookCreateModal.vue";
import PhonebookNumbersModal from "@/views/pages/other/phoneBook/component/PhonebookNumbersModal.vue";
import Empty from "@/components/EmptyDataReplacement.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "multiple-messages",
  components: {
    Empty,
    PhonebookCreateModal,
    PhonebookNumbersModal,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const loading = ref(true);
    const list = ref();
    const pagination = ref();
    const page = ref(1);
    const selected = ref();
    const modalOpened = ref(0);
    const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;

    const getItems = () => {
      let endPointUrl = "phonebook";
      let params = { page: page.value };
      ApiService.setHeader();
      ApiService.query(endPointUrl, { params })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.phonebooks.data;
          pagination.value = data.data.phonebooks.pagination;
        })
        .catch(() => {
          // ElNotification({
          //   title: "Info",
          //   message: "خطا در بارگزاری لیست پیام‌های انبوه",
          //   type: "info",
          // });
        });
    };

    onMounted(() => {
      getItems();
      setCurrentPageBreadcrumbs(translate("Phonebook"), []);
    });

    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };
    const openCreateForm = () => {
      selected.value = undefined;
    };
     const download_excel = (id) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `phonebook/${
          id
        }/download?api_token=${JwtService.getToken()}`
      );
    };

    const remove = (id) => {
      if (
        !confirm(translate("Are you sure you want to delete this phonebook?"))
      )
        return;

      ApiService.post(`phonebook/${id}/delete`, {})
        .then(() => {
          getItems();
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("Error deleting phonebook"),
            type: "error",
          });
        });
    };

    return {
      list,
      loading,
      svg,
      pagination,
      handleCurrentChange,
      handleSizeChange,
      page,
      modalOpened,
      getItems,
      openCreateForm,
      remove,
      selected,
      translate,
      download_excel,
    };
  },
});
